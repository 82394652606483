import { combineReducers } from "redux";
import { cart, totalItem } from "./cart";
import { chats, totalUnread } from "./chat";
import { families } from "./family";
import { merchants } from "./merchant";
import { needs } from "./need";
import { order, orders } from "./order";
import { product, products } from "./product";
import { user } from "./user";
import { districts, provinces, regencies, villages } from "./wilayah";
import { reviews } from "./review";

const reducer = combineReducers({
  provinces,
  regencies,
  districts,
  villages,
  needs,
  families,
  merchants,
  products,
  product,
  cart,
  totalItem,
  orders,
  order,
  chats,
  totalUnread,
  user,
  reviews,
});

export default reducer;
