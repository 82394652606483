import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getTotalItem, storeCart } from "../../../../redux/action/cart";
import Gap from "../../../atoms/Gap";
import LinkLogin from "../../../organisms/LinkLogin";

const SectionProduct = ({ setLoading }) => {
  const [image, setImage] = useState();
  const [name, setName] = useState();
  const [price, setPrice] = useState();
  const [priceWithDiscount, setPriceWithDiscount] = useState();
  const [stock, setStock] = useState();
  const [itemId, setItemId] = useState();
  const [quantity, setQuantity] = useState();

  const { product } = useSelector((state) => state.product);
  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const btnCart = () => {
    setLoading(true);
    const form = {
      merchantId: product.merchantId,
      itemId,
      quantity,
    };

    storeCart(form, false)
      .then((res) => {
        toast(res.message);
        dispatch(getTotalItem());
      })
      .catch((err) => {
        if (err.message === "validation failed") {
          toast("Pilih VARIAN dan tentukan KUANTITAS terlebih dahulu");
        } else {
          toast(err.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const navigate = useNavigate();

  const btnCartDirect = () => {
    setLoading(true);
    const form = {
      merchantId: product.merchantId,
      itemId,
      quantity,
    };

    storeCart(form, true)
      .then((res) => {
        toast(res.message);
        navigate("/cart?direct=true");
      })
      .catch((err) => {
        if (err.message === "validation failed") {
          toast("Pilih VARIAN dan tentukan KUANTITAS terlebih dahulu");
        } else {
          toast(err.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="card" style={styles.card}>
      <div className="valign-wrapper">
        <img
          src={!image ? `//${product.image}` : `//${image}`}
          className="responsive-img"
          style={styles.image}
        />
        <div style={styles.container}>
          <div className="red-text" style={styles.priceWithDiscount}>
            <b>{priceWithDiscount}</b>
          </div>
          <div style={styles.price}>
            <s>{price}</s>
          </div>
          <div style={styles.name}>{name}</div>
        </div>
      </div>
      <Gap height={10} />
      <div style={styles.product}>
        <b>{product.name}</b>
      </div>
      <div style={styles.title}>Pilih Varian</div>
      <Gap height={5} />
      <div className="row" style={styles.row}>
        {product.Items?.map((value, index) => (
          <a
            href="#"
            className={
              itemId == value.id
                ? "btn orange accent-3 white-text col"
                : "btn white grey-text text-darken-3 col"
            }
            style={styles.btnItem}
            onClick={() => {
              setItemId(value.id);
              setImage(value.image);
              setName(value.name);
              setPrice(value.price);
              setPriceWithDiscount(value.priceWithDiscount);
              setStock(value.stock);
              setQuantity(0);
            }}
            key={index}
          >
            {value.name}
          </a>
        ))}
      </div>
      <div style={styles.spaceBetween}>
        <div style={styles.title}>Kuantitas</div>
        <div className="valign-wrapper">
          <button
            className="btn btn-floating white"
            onClick={() => (quantity !== 0 ? setQuantity(quantity - 1) : null)}
          >
            <i className="material-icons grey-text text-darken-3">remove</i>
          </button>
          <b className="grey-text text-darken-3" style={styles.quantity}>
            {quantity}
          </b>
          <button
            className="btn btn-floating white"
            onClick={() =>
              quantity !== stock ? setQuantity(quantity + 1) : null
            }
          >
            <i className="material-icons grey-text text-darken-3">add</i>
          </button>
        </div>
      </div>
      <Gap height={15} />
      {Object.keys(user).length > 0 ? (
        <div style={styles.flex}>
          <a
            href={`/chat/merchant/${product.merchantId}?productId=${product.id}`}
            className="btn grey lighten-1"
            style={styles.btnComment}
          >
            <i className="fa-regular fa-comments"></i>
          </a>
          <button
            className="btn orange"
            style={styles.btn}
            onClick={() => {
              btnCart();
            }}
          >
            <i className="fa-solid fa-cart-plus"></i>
          </button>
          <button
            className="btn"
            style={styles.btnDirect}
            onClick={() => btnCartDirect()}
          >
            <div style={styles.direct}>Pesan</div>
            <div>Langsung</div>
          </button>
        </div>
      ) : (
        <LinkLogin />
      )}
    </div>
  );
};

export default SectionProduct;

const styles = {
  card: { padding: 10, borderRadius: 10 },
  image: { width: 150, height: 150 },
  container: { marginLeft: 10, overflow: "auto" },
  priceWithDiscount: { fontSize: 20 },
  price: { fontSize: 16 },
  name: { fontSize: 16 },
  product: { fontSize: 16 },
  title: { fontSize: 16 },
  row: { marginBottom: 0 },
  btnItem: {
    marginRight: 5,
    marginBottom: 5,
    borderRadius: 10,
  },
  spaceBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  quantity: { fontSize: 16, marginLeft: 10, marginRight: 10 },
  flex: { display: "flex" },
  btnComment: { borderRadius: 10, marginRight: 2.5 },
  btn: {
    borderRadius: 10,
  },
  btnDirect: { marginLeft: "auto", borderRadius: 10 },
  direct: { marginTop: -7, marginBottom: -22 },
};
