import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { convertToBase64 } from "../../../redux/action/convertToBase64";
import { upload } from "../../../redux/action/order";
import Gap from "../../atoms/Gap";

const SectionForm = ({ setLoading }) => {
  const { order } = useSelector((state) => state.order);

  const [previewImage, setPreviewImage] = useState();
  const [proofOfPayment, setProofOfPayment] = useState();
  const [validation, setValidation] = useState([]);

  const handlePreviewImage = (e) => {
    const [file] = e.target.files;

    if (!file) return;

    setPreviewImage(URL.createObjectURL(file));
  };

  const handleFileUpload = async (e) => {
    const [file] = e.target.files;

    if (!file) return;

    const { size, type } = file;

    if (type !== "image/jpg" && type !== "image/jpeg" && type !== "image/png") {
      toast("Tipe yang diperbolehkan hanya jpg, jpeg, & png");
      setProofOfPayment();
    } else if (size > 1000000) {
      toast("Ukuran gambar maksimal 1 mb");
      setProofOfPayment();
    } else {
      setProofOfPayment(await convertToBase64(file));
    }
  };

  const navigate = useNavigate();

  const formProofOfPayment = (e) => {
    e.preventDefault();
    setLoading(true);

    const form = {
      proofOfPayment,
    };

    upload(order.id, form)
      .then((res) => {
        toast(res.message);
        navigate("/order?status=");
      })
      .catch((err) => {
        if (err.message === "validation failed") {
          setValidation(err.data);
        } else {
          toast(err.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <form onSubmit={formProofOfPayment}>
      <div className="card" style={styles.card}>
        <div style={styles.title}>Bukti Pembayaran</div>
        <Gap height={10} />
        <div className="center">
          {!previewImage ? (
            <img
              src={`//${order.proofOfPayment}`}
              className="responsive-img"
              style={styles.oldImage}
            />
          ) : (
            <img
              src={previewImage}
              className="responsive-img"
              style={styles.image}
            />
          )}
        </div>
        <div className="file-field input-field">
          <div className="btn">
            <i className="material-icons">image</i>
            <input
              type="file"
              onChange={(e) => {
                handlePreviewImage(e);
                handleFileUpload(e);
              }}
            />
          </div>
          <div className="file-path-wrapper">
            <input className="file-path validate" type="text" />
          </div>
          {validation?.map((value, index) =>
            value.field === "proofOfPayment" ? (
              <span className="helper-text red-text">{value.message}</span>
            ) : null
          )}
        </div>
        <div className="center">
          <button className="btn">Unggah Bukti Pembayaran</button>
        </div>
      </div>
    </form>
  );
};

export default SectionForm;

const styles = {
  card: { padding: 10, borderRadius: 10 },
  title: { fontSize: 16 },
  oldImage: { width: 150 },
  image: { width: 150 },
};
