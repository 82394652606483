import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { HashLoader } from "react-spinners";
import Gap from "../../components/atoms/Gap";
import SectionMerchant from "../../components/organisms/Merchant/SectionMerchant";
import { getMerchants } from "../../redux/action/merchant";

const Merchant = () => {
  let [searchParams] = useSearchParams();
  const needId = searchParams.get("needId");

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    getMerchants(needId)
      .then((res) => {
        dispatch({
          type: "GET_MERCHANTS",
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <HashLoader color="#ff9100" style={styles.loading} />;
  }

  return (
    <>
      <div className="container">
        <SectionMerchant needId={needId} />
      </div>
      <Gap height={50} />
    </>
  );
};

export default Merchant;

const styles = {
  loading: {
    justifyContent: "center",
  },
};
