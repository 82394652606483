import { useSelector } from "react-redux";
import Gap from "../../../atoms/Gap";

const SectionDescription = () => {
  const { product } = useSelector((state) => state.product);

  return (
    <div className="card" style={styles.card}>
      <div style={styles.title}>Deskripsi</div>
      <Gap height={10} />
      <div style={styles.description}>{product.description}</div>
    </div>
  );
};

export default SectionDescription;

const styles = {
  card: { padding: 10, borderRadius: 10 },
  title: { fontSize: 16 },
  description: {
    fontSize: 16,
  },
};
