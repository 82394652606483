import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GlobalApp from "./layouts/GlobalApp";
import MainApp from "./layouts/MainApp";
import Cart from "./pages/Cart";
import Chat from "./pages/Chat";
import ChatMerchant from "./pages/Chat/Merchant";
import Feed from "./pages/Feed";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Merchant from "./pages/Merchant";
import My from "./pages/My";
import Order from "./pages/Order";
import OrderDetail from "./pages/Order/Detail";
import Payment from "./pages/Payment";
import Product from "./pages/Product";
import ProductDetail from "./pages/Product/Detail";
import Register from "./pages/Register";
import Review from "./pages/Review";
import store from "./redux/store";

function App() {
  return (
    <Provider store={store}>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="" element={<GlobalApp />}>
            <Route path="register" element={<Register />} />
            <Route path="login" element={<Login />} />
          </Route>
          <Route path="" element={<MainApp />}>
            <Route index element={<Home />} />
            <Route path="merchant" element={<Merchant />} />
            <Route path="product">
              <Route path="merchant/:merchantId" element={<Product />} />
              <Route path=":id" element={<ProductDetail />} />
            </Route>
            <Route path="cart">
              <Route index element={<Cart />} />
            </Route>
            <Route path="order">
              <Route index element={<Order />} />
              <Route path=":id" element={<OrderDetail />} />
            </Route>
            <Route path="payment/order/:orderId" element={<Payment />} />
            <Route path="chat">
              <Route index element={<Chat />} />
              <Route path="merchant/:merchantId" element={<ChatMerchant />} />
            </Route>
            <Route path="review/:orderId">
              <Route index element={<Review />} />
            </Route>
            <Route path="feed" element={<Feed />} />
            <Route path="my" element={<My />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
