import { useSelector } from "react-redux";
import Gap from "../../../atoms/Gap";

const SectionReview = () => {
  const { product } = useSelector((state) => state.product);

  let stars = [];
  for (let i = 0; i < Math.floor(product.totalRating); i++) {
    stars.push("star");
  }
  if (product.totalRating != Math.floor(product.totalRating)) {
    stars.push("star_half");
  }
  for (let i = 0; i < Math.floor(5 - product.totalRating); i++) {
    stars.push("star_border");
  }

  return (
    <div className="card" style={styles.card}>
      <div style={styles.spaceBetween}>
        <div style={styles.title}>Ulasan ({product.Reviews?.length})</div>
        <div className="valign-wrapper">
          <div style={styles.totalRating}>{product.totalRating}</div>
          <div>
            {stars.map((value, index) => (
              <i
                className="material-icons yellow-text text-darken-2"
                style={styles.star}
                key={index}
              >
                {value}
              </i>
            ))}
          </div>
        </div>
      </div>
      {product.Reviews?.map((value, index) => (
        <div className="card" style={styles.card} key={index}>
          <div className="valign-wrapper">
            {(() => {
              let stars = [];
              for (let i = 0; i < value.rating; i++) {
                stars.push(
                  <i
                    className="material-icons yellow-text text-darken-2"
                    style={styles.star}
                  >
                    star
                  </i>
                );
              }
              for (let i = 0; i < 5 - value.rating; i++) {
                stars.push(
                  <i
                    className="material-icons yellow-text text-darken-2"
                    style={styles.star}
                  >
                    star_border
                  </i>
                );
              }
              return stars;
            })()}
            <div style={styles.customer}>{value.Customer.name}</div>
          </div>
          <Gap height={5} />
          <div className="grey-text" style={styles.item}>
            {value.itemName}
          </div>
          <div style={styles.review}>{value.review}</div>
          <Gap height={5} />
          <div className="grey-text" style={styles.date}>
            {value.createdAt}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SectionReview;

const styles = {
  card: { padding: 10, borderRadius: 10 },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontSize: 16,
  },
  totalRating: {
    fontSize: 16,
  },
  star: { fontSize: 16 },
  customer: { fontSize: 13, marginLeft: 5 },
  item: { fontSize: 13 },
  review: { fontSize: 14 },
  date: { fontSize: 13, justifyContent: "end" },
};
