import Api from "../../../api/Api";

const getProducts = (merchantId, needId = "", familyId = "") => {
  return new Promise((resolve, reject) => {
    if (needId && familyId) {
      Api.get(
        `/customer-products?merchantId=${merchantId}&needId=${needId}&familyId=${familyId}`
      )
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    } else if (needId) {
      Api.get(`/customer-products?merchantId=${merchantId}&needId=${needId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    } else {
      Api.get(`/customer-products?merchantId=${merchantId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    }
  });
};

const getProduct = (id) => {
  return new Promise((resolve, reject) => {
    Api.get(`/customer-products/${id}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

export { getProduct, getProducts };
