import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { HashLoader } from "react-spinners";
import Gap from "../../../components/atoms/Gap";
import SectionDescription from "../../../components/organisms/Product/Detail/SectionDescription";
import SectionProduct from "../../../components/organisms/Product/Detail/SectionProduct";
import SectionReview from "../../../components/organisms/Product/Detail/SectionReview";
import { authentication } from "../../../redux/action/auth";
import { getProduct } from "../../../redux/action/product";

const ProductDetail = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    getProduct(id)
      .then((res) => {
        dispatch({
          type: "GET_PRODUCT",
          payload: res.data,
        });
      })
      .then(() => {
        authentication().then((res) => {
          dispatch({
            type: "GET_USER",
            payload: res.data,
          });
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <HashLoader color="#ff9100" style={styles.loading} />;
  }

  return (
    <>
      <div className="container">
        <SectionProduct setLoading={setLoading} />
        <SectionDescription />
        <SectionReview />
      </div>
      <Gap height={70} />
    </>
  );
};

export default ProductDetail;

const styles = {
  loading: {
    justifyContent: "center",
  },
};
