import Api from "../../../api/Api";

const getToken = () => {
  return localStorage.getItem("token-customer");
};

const storeRegister = (form) => {
  return new Promise((resolve, reject) => {
    Api.post("/customer-customers", form)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const storeLogin = (form) => {
  return new Promise((resolve, reject) => {
    Api.post("/customer-customers/login", form)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const authentication = () => {
  return new Promise((resolve, reject) => {
    Api.get("/customer-customers", {
      headers: {
        Authorization: getToken(),
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const getUser = () => (dispatch) => {
  Api.get("/customer-customers", { headers: { Authorization: getToken() } })
    .then((res) => {
      dispatch({
        type: "GET_USER",
        payload: res.data.data,
      });
    })
    .catch((err) => {
      console.log(err.response.data);
    });
};

export { storeRegister, storeLogin, authentication, getUser };
