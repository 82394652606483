import { Link } from "react-router-dom";

const LinkLogin = () => {
  return (
    <div className="center">
      <Link to="/login" style={styles.link}>
        Silahkan masuk terlebih dahulu! Klik Disini!
      </Link>
    </div>
  );
};

export default LinkLogin;

const styles = {
  link: {
    fontSize: 16,
  },
};
