import M from "materialize-css";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const SectionFilter = ({
  needId,
  filterNeed,
  filterFamily,
  filterFamilyId,
  setFilterFamilyId,
}) => {
  useEffect(() => {
    var elemsSelect = document.querySelectorAll("select");
    M.FormSelect.init(elemsSelect, {});
  });

  const { needs } = useSelector((state) => state.needs);
  const { families } = useSelector((state) => state.families);

  return (
    <div className="col s12">
      <div className="card" style={styles.card}>
        <select
          value={needId ? needId : "all"}
          onChange={(e) => {
            filterNeed(e.target.value);
          }}
        >
          <option disabled>Pilih Kategori</option>
          <option value="all">Semua</option>
          {needs.map((value, index) => (
            <option value={value.id} key={index}>
              {value.name}
            </option>
          ))}
        </select>
        {families.map((value, index) => (
          <button
            className={`btn-small ${
              value.id !== filterFamilyId ? "grey lighten-1" : "orange"
            }`}
            style={styles.button}
            onClick={() => {
              setFilterFamilyId(value.id);
              filterFamily(value.id);
            }}
            key={index}
          >
            {value.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SectionFilter;

const styles = {
  card: {
    padding: 10,
    borderRadius: 10,
  },
  button: {
    margin: 2,
    borderRadius: 10,
  },
};
