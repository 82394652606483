import { useSelector } from "react-redux";
import Gap from "../../atoms/Gap";

const SectionOrder = () => {
  const { order } = useSelector((state) => state.order);

  return (
    <ul className="collapsible" style={styles.card}>
      <li>
        <div className="collapsible-header" style={styles.title}>
          Rincian
        </div>
        <div className="collapsible-body" style={styles.container}>
          <div style={styles.spaceBetween}>
            <div>{order.id}</div>
            <div style={styles.date}>{order.createdAt}</div>
          </div>
          <div style={styles.spaceBetween}>
            <div>
              <b>{order.merchant?.name}</b>
            </div>
            <div>{order.totalItem}</div>
          </div>
          <Gap height={10} />
          {order.OrderItems?.map((value, index) => (
            <div key={index}>
              <div style={styles.containerItem}>
                <div style={styles.containerImage}>
                  <img
                    src={`//${value.image}`}
                    className="responsive-img"
                    style={styles.image}
                  />
                </div>
                <div style={styles.containerProduct}>
                  <div>{value.productName}</div>
                  <div>{value.itemName}</div>
                  <div style={styles.containerQuantity}>
                    <div>{`${value.quantity} x ${value.price}`}</div>
                  </div>
                </div>
              </div>
              <Gap height={10} />
            </div>
          ))}
          <div style={styles.containerNote}>
            <div>Catatan:</div>
            <div>{order.note}</div>
          </div>
          <Gap height={5} />
          <div style={styles.containerStatus}>
            <b>{order.status}</b>
          </div>
          <div style={styles.spaceBetween}>
            <div>Metode Pembayaran</div>
            <div>{order.paymentMethod}</div>
          </div>
          <div style={styles.spaceBetween}>
            <div>
              <b>Total Pembayaran</b>
            </div>
            <div className="red-text">
              <b>{order.grossAmount}</b>
            </div>
          </div>
        </div>
      </li>
    </ul>
  );
};

export default SectionOrder;

const styles = {
  card: { borderRadius: 10 },
  title: {
    fontSize: 16,
  },
  container: { marginTop: -18, marginLeft: -18, marginRight: -18 },
  spaceBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 16,
  },
  date: { fontSize: 14 },
  containerItem: { display: "flex", alignItems: "center" },
  containerImage: { marginRight: 10 },
  image: { width: 60, height: 60 },
  containerProduct: { fontSize: 16 },
  containerQuantity: { marginLeft: "auto" },
  containerNote: { fontSize: 16 },
  containerStatus: { display: "flex", justifyContent: "end", fontSize: 16 },
};
