import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import Gap from "../../../components/atoms/Gap";
import SectionAttach from "../../../components/organisms/Chat/SectionAttach";
import SectionChat from "../../../components/organisms/Chat/SectionChat";
import SectionFormDesktop from "../../../components/organisms/Chat/SectionFormDesktop";
import SectionFormMobile from "../../../components/organisms/Chat/SectionFormMobile";
import { authentication } from "../../../redux/action/auth";
import {
  getChats,
  storeChat,
} from "../../../redux/action/merchantCustomerChat";
import { getOrder } from "../../../redux/action/order";
import { getProduct } from "../../../redux/action/product";
import { HashLoader } from "react-spinners";

const ChatMerchant = () => {
  const { merchantId } = useParams();

  let [searchParams] = useSearchParams();
  const productId = searchParams.get("productId");
  const orderId = searchParams.get("orderId");

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("token-customer")) {
      authentication()
        .then((res) => {
          dispatch({
            type: "GET_USER",
            payload: res.data,
          });
          if (productId) {
            getProduct(productId)
              .then((res) => {
                dispatch({
                  type: "GET_PRODUCT",
                  payload: res.data,
                });
                getChats(merchantId)
                  .then((res) => {
                    dispatch({
                      type: "GET_CHATS",
                      payload: res.data,
                    });
                    setLoading(false);
                  })
                  .catch((err) => {});
              })
              .catch((err) => {});
          }
          if (orderId) {
            getOrder(orderId)
              .then((res) => {
                dispatch({
                  type: "GET_ORDER",
                  payload: res.data,
                });
                getChats(merchantId)
                  .then((res) => {
                    dispatch({
                      type: "GET_CHATS",
                      payload: res.data,
                    });
                    setLoading(false);
                  })
                  .catch((err) => {});
              })
              .catch((err) => {});
          }
          if (!productId && !orderId) {
            getChats(merchantId)
              .then((res) => {
                dispatch({
                  type: "GET_CHATS",
                  payload: res.data,
                });
                setLoading(false);
              })
              .catch((err) => {});
          }
        })
        .catch((err) => {
          window.location.href = "/login";
        });
    } else {
      window.location.href = "/login";
    }
  }, [dispatch]);

  const { chats } = useSelector((state) => state.chats);

  const [message, setMessage] = useState();

  const formMessage = (e) => {
    e.preventDefault();

    const form = {
      message: message,
      productId,
      orderId,
    };

    if (productId) {
      storeChat(merchantId, form)
        .then((res) => {
          window.location.href = `/chat/merchant/${merchantId}`;
        })
        .catch((err) => {
          alert("pesan tidak boleh kosong!");
        });
    }
    if (orderId) {
      storeChat(merchantId, form)
        .then((res) => {
          window.location.href = `/chat/merchant/${merchantId}`;
        })
        .catch((err) => {
          alert("pesan tidak boleh kosong!");
        });
    }
    if (!productId && !orderId) {
      {
        storeChat(merchantId, form)
          .then((res) => {
            window.location.reload();
          })
          .catch((err) => {
            alert("pesan tidak boleh kosong!");
          });
      }
    }
  };

  if (loading) {
    return <HashLoader color="#ff9100" style={styles.loading} />;
  }

  return (
    <>
      <div className="container">
        {/* chat */}
        <SectionChat chats={chats} />
        {/* attach */}
        <SectionAttach productId={productId} orderId={orderId} />
      </div>
      <Gap height={150} />
      {/* desktop */}
      <SectionFormDesktop
        message={message}
        setMessage={setMessage}
        formMessage={formMessage}
      />
      {/* mobile */}
      <SectionFormMobile
        message={message}
        setMessage={setMessage}
        formMessage={formMessage}
      />
    </>
  );
};

export default ChatMerchant;

const styles = {
  loading: {
    justifyContent: "center",
  },
};
