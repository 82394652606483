import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Gap from "../../atoms/Gap";

const SectionMerchant = ({ needId }) => {
  const { merchants } = useSelector((state) => state.merchants);

  return (
    <div className="row" style={styles.row}>
      {merchants.map((value, index) => (
        <Link
          to={`/product/merchant/${value.id}?needId=${needId}`}
          className="col s6 m4 l3 grey-text text-darken-3"
          style={styles.link}
          key={index}
        >
          <div className="card center" style={styles.card}>
            <img
              src={`//${value.image}`}
              className="responsive-img"
              style={styles.image}
            />
            <Gap height={10} />
            <div style={styles.name}>{value.name}</div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default SectionMerchant;

const styles = {
  row: { marginLeft: -10, marginRight: -10 },
  link: { paddingLeft: 10, paddingRight: 10 },
  card: {
    padding: 10,
    borderRadius: 10,
  },
  image: { width: 100, height: 100 },
  name: { fontSize: 17 },
};
