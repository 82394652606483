import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const SectionProduct = () => {
  const { products } = useSelector((state) => state.products);

  return (
    <>
      {products.map((value, index) => (
        <Link
          to={`/product/${value.id}`}
          className="col s6 m4 l3 grey-text text-darken-3"
          key={index}
        >
          <div className="card center" style={styles.card}>
            <img
              src={`//${value.image}`}
              className="responsive-img"
              style={{ width: 100, height: 100 }}
            />
            <div className="grey-text text-darken-3" style={{ fontSize: 16 }}>
              {value.name}
            </div>
            <div className="red-text" style={{ fontSize: 16 }}>
              {value.price}
            </div>
          </div>
        </Link>
      ))}
    </>
  );
};

export default SectionProduct;

const styles = {
  card: {
    padding: 10,
    borderRadius: 10,
  },
  button: {
    borderRadius: 10,
  },
};
