const SectionAmount = ({ cart }) => {
  return (
    <div className="card" style={styles.card}>
      <div style={styles.container}>
        <div>Harga ({cart.totalItem})</div>
        <div>{cart.totalPrice}</div>
      </div>
      <div style={styles.container}>
        <div>Payment Tax</div>
        <div>{cart.paymentTax}</div>
      </div>
      <div style={styles.container}>
        <div>Service Fee</div>
        <div>{cart.serviceFee}</div>
      </div>
      <div style={styles.container}>
        <div>Ongkir</div>
        <div>{cart.deliveryFee}</div>
      </div>
      <div style={styles.container}>
        <div>Total Pembayaran</div>
        <div className="red-text">
          <b>{cart.grossAmount}</b>
        </div>
      </div>
    </div>
  );
};

export default SectionAmount;

const styles = {
  card: { padding: 10, borderRadius: 10 },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: 16,
  },
};
