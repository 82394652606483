import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HashLoader } from "react-spinners";
import Gap from "../../components/atoms/Gap";
import SectionNearbyMerchant from "../../components/organisms/Home/SectionNearbyMerchant";
import SectionNeed from "../../components/organisms/Home/SectionNeed";
import { getNeeds } from "../../redux/action/need";

const Home = () => {
  const [loading, setLoading] = useState(true);

  const { needs } = useSelector((state) => state.needs);

  const dispatch = useDispatch();

  useEffect(() => {
    getNeeds()
      .then((res) => {
        dispatch({
          type: "GET_NEEDS",
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <HashLoader color="#ff9100" style={styles.loading} />;
  }

  return (
    <>
      <div className="container">
        <SectionNeed needs={needs} />
        <div style={{ fontSize: 16 }}>
          <b>Rekomendasi terdekat di areamu</b>
          <div>Kami pilihin yang terdekat.</div>
        </div>
        <Gap height={5} />
        <SectionNearbyMerchant />
      </div>
      <Gap height={50} />
    </>
  );
};

export default Home;

const styles = {
  loading: {
    justifyContent: "center",
  },
};
