import M from "materialize-css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { HashLoader } from "react-spinners";
import Gap from "../../components/atoms/Gap";
import SectionForm from "../../components/organisms/Payment/SectionForm";
import SectionInstruction from "../../components/organisms/Payment/SectionInstruction";
import SectionOrder from "../../components/organisms/Payment/SectionOrder";
import { authentication } from "../../redux/action/auth";
import { getOrder } from "../../redux/action/order";

const Payment = () => {
  useEffect(() => {
    var elems = document.querySelectorAll(".collapsible");
    M.Collapsible.init(elems, {});
  });

  const { orderId } = useParams();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!localStorage.getItem("token-customer")) {
      window.location.href = "/login";
    }

    authentication()
      .then(() => {
        getOrder(orderId).then((res) => {
          dispatch({
            type: "GET_ORDER",
            payload: res.data,
          });
        });
      })
      .catch((err) => {
        if (err.message === "invalid token") {
          window.location.href = "/login";
        } else {
          console.log(err);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <HashLoader color="#ff9100" style={styles.loading} />;
  }

  return (
    <>
      <div className="container">
        <SectionInstruction />
        <SectionOrder />
        <SectionForm setLoading={setLoading} />
        <Link to="/order" className="btn grey" style={styles.btn}>
          <i className="material-icons">arrow_back</i>
        </Link>
      </div>
      <Gap height={70} />
    </>
  );
};

export default Payment;

const styles = {
  btn: { borderRadius: 10 },
  loading: {},
};
