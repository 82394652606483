import M from "materialize-css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Gap from "../../components/atoms/Gap";
import { getReviews, storeReview } from "../../redux/action/review";
import { HashLoader } from "react-spinners";

const Review = () => {
  useEffect(() => {
    var elems = document.querySelectorAll(".modal");
    M.Modal.init(elems, {});
  }, []);

  const { orderId } = useParams();

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    getReviews(orderId)
      .then((res) => {
        dispatch({
          type: "GET_REVIEWS",
          payload: res.data,
        });
        setLoading(false);
      })
      .catch((err) => {});
  }, []);

  const { reviews } = useSelector((state) => state.reviews);

  const [merchantId, setMerchantId] = useState();
  const [orderItemId, setOrderItemId] = useState();
  const [productId, setProductId] = useState();
  const [itemId, setItemId] = useState();
  const [productName, setProductName] = useState();
  const [itemName, setItemName] = useState();
  const [rating, setRating] = useState();
  const [review, setReview] = useState();
  const [validation, setValidation] = useState([]);

  const formReview = (e) => {
    e.preventDefault();

    const form = {
      merchantId,
      orderItemId,
      productId,
      itemId,
      productName,
      itemName,
      rating: parseInt(rating),
      review,
    };

    storeReview(form)
      .then((res) => {
        alert(res.message);
        window.location.reload();
      })
      .catch((err) => {
        if (err.message === "validation failed") {
          setValidation(err.data);
        } else {
          alert(err.message);
        }
      });
  };

  if (loading) {
    return <HashLoader color="#ff9100" style={styles.loading} />;
  }

  return (
    <div className="container">
      <div className="card" style={styles.card}>
        {reviews.map((value, index) =>
          value.OrderItems.map((v, i) => (
            <>
              <div style={styles.container}>
                <img
                  src={`//${v.image}`}
                  className="responsive-img"
                  style={styles.image}
                />
                <div>
                  <div style={styles.product}>{v.productName}</div>
                  <div style={styles.item}>{v.itemName}</div>
                  <Gap height={5} />
                  <div>
                    {v.Review?.rating ? (
                      (() => {
                        let stars = [];
                        for (let i = 0; i < v.Review.rating; i++) {
                          stars.push(
                            <i
                              className="material-icons yellow-text text-darken-2"
                              style={styles.star}
                            >
                              star
                            </i>
                          );
                        }
                        for (let i = 0; i < 5 - v.Review.rating; i++) {
                          stars.push(
                            <i
                              className="material-icons yellow-text text-darken-2"
                              style={styles.star}
                            >
                              star_border
                            </i>
                          );
                        }
                        return stars;
                      })()
                    ) : (
                      <a
                        href="#modal1"
                        className="btn-small orange modal-trigger"
                        style={styles.btn}
                        onClick={() => {
                          setMerchantId(value.merchantId);
                          setOrderItemId(v.id);
                          setProductId(v.productId);
                          setItemId(v.itemId);
                          setProductName(v.productName);
                          setItemName(v.itemName);
                          setRating("");
                          setReview("");
                        }}
                      >
                        Kasih Ulasan
                      </a>
                    )}
                  </div>
                </div>
              </div>
              <Gap height={10} />
            </>
          ))
        )}
        <div id="modal1" class="modal">
          <div class="modal-content center">
            <form onSubmit={formReview}>
              <div>
                <i
                  className="material-icons yellow-text text-darken-2"
                  onClick={() => {
                    setRating(1);
                  }}
                >
                  {rating === 1 ||
                  rating === 2 ||
                  rating === 3 ||
                  rating === 4 ||
                  rating === 5
                    ? "star"
                    : "star_border"}
                </i>
                <i
                  className="material-icons yellow-text text-darken-2"
                  onClick={() => {
                    setRating(2);
                  }}
                >
                  {rating === 2 || rating === 3 || rating === 4 || rating === 5
                    ? "star"
                    : "star_border"}
                </i>
                <i
                  className="material-icons yellow-text text-darken-2"
                  onClick={() => {
                    setRating(3);
                  }}
                >
                  {rating === 3 || rating === 4 || rating === 5
                    ? "star"
                    : "star_border"}
                </i>
                <i
                  className="material-icons yellow-text text-darken-2"
                  onClick={() => {
                    setRating(4);
                  }}
                >
                  {rating === 4 || rating === 5 ? "star" : "star_border"}
                </i>
                <i
                  className="material-icons yellow-text text-darken-2"
                  onClick={() => {
                    setRating(5);
                  }}
                >
                  {rating === 5 ? "star" : "star_border"}
                </i>
              </div>
              {validation?.map((value, index) =>
                value.field === "rating" ? (
                  <span className="helper-text red-text" key={index}>
                    {value.message}
                  </span>
                ) : null
              )}
              <div className="input-field">
                <input
                  type="text"
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                />
                <label>Ulasan</label>
                {validation?.map((value, index) =>
                  value.field === "review" ? (
                    <span className="helper-text red-text" key={index}>
                      {value.message}
                    </span>
                  ) : null
                )}
              </div>
              <button className="btn">Kirim Ulasan</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;

const styles = {
  card: { padding: 10, borderRadius: 10 },
  container: { display: "flex", alignItems: "center" },
  image: { width: 60, height: 60, marginRight: 10 },
  product: { fontSize: 16 },
  item: { fontSize: 16 },
  star: { fontSize: 14 },
  btn: { borderRadius: 10 },
  loading: {
    justifyContent: "center",
  },
};
