import Gap from "../../atoms/Gap";

const SectionItem = ({ cart, btnQuantity }) => {
  return (
    <div className="card" style={styles.card}>
      {cart.Cart?.map((value, index) => (
        <div key={index}>
          <div style={styles.containerItem}>
            <div style={styles.containerImage}>
              <img
                src={`//${value.Item.image}`}
                className="responsive-img"
                style={styles.image}
              />
            </div>
            <div style={styles.containerProduct}>
              <div style={styles.product}>{value.Item.Product.name}</div>
              <div style={styles.item}>{value.Item.name}</div>
              <s style={styles.price}>{value.Item.price}</s>
              <div className="red-text" style={styles.priceWithDiscount}>
                {value.priceWithDiscount}
              </div>
            </div>
            <div style={styles.containerQuantity}>
              <div className="valign-wrapper">
                <button
                  className="btn-small btn-floating white"
                  onClick={() => {
                    btnQuantity(value.itemId, "decrease");
                  }}
                >
                  <i className="material-icons grey-text text-darken-3">
                    remove
                  </i>
                </button>
                <b style={styles.quantity}>{value.quantity}</b>
                <button
                  className="btn-small btn-floating white"
                  onClick={() => {
                    {
                      btnQuantity(value.itemId, "increase");
                    }
                  }}
                >
                  <i className="material-icons grey-text text-darken-3">add</i>
                </button>
              </div>
            </div>
          </div>
          <Gap height={10} />
        </div>
      ))}
    </div>
  );
};

export default SectionItem;

const styles = {
  card: {
    padding: 10,
    borderRadius: 10,
  },
  containerItem: {
    display: "flex",
    alignItems: "center",
  },
  containerImage: { marginRight: 10 },
  image: { width: 60, height: 60 },
  containerProduct: {
    marginRight: 10,
  },
  product: { fontSize: 15 },
  item: { fontSize: 15 },
  price: { fontSize: 14 },
  priceWithDiscount: { fontSize: 15 },
  containerQuantity: { marginLeft: "auto" },
  quantity: {
    marginLeft: 5,
    marginRight: 5,
    fontSize: 16,
  },
};
