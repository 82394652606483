import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { HashLoader } from "react-spinners";
import Gap from "../../components/atoms/Gap";
import SectionFilter from "../../components/organisms/Product/SectionFilter";
import SectionProduct from "../../components/organisms/Product/SectionProduct";
import { getFamilies } from "../../redux/action/family";
import { getNeeds } from "../../redux/action/need";
import { getProducts } from "../../redux/action/product";

const Product = () => {
  const { merchantId } = useParams();
  let [searchParams] = useSearchParams();
  const needId = searchParams.get("needId");

  const [loading, setLoading] = useState(true);
  const [filterFamilyId, setFilterFamilyId] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    getNeeds(merchantId)
      .then((res) => {
        dispatch({
          type: "GET_NEEDS",
          payload: res.data,
        });
      })
      .then(() => {
        getFamilies(merchantId, needId).then((res) => {
          dispatch({
            type: "GET_FAMILIES",
            payload: res.data,
          });
        });
      })
      .then(() => {
        getProducts(merchantId, needId).then((res) => {
          dispatch({
            type: "GET_PRODUCTS",
            payload: res.data,
          });
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const filterNeed = (value) => {
    if (value === "all") {
      window.location.href = `/product/merchant/${merchantId}`;
    } else {
      window.location.href = `/product/merchant/${merchantId}?needId=${value}`;
    }
  };

  const filterFamily = (id) => {
    setLoading(true);
    getProducts(merchantId, needId, id)
      .then((res) => {
        dispatch({
          type: "GET_PRODUCTS",
          payload: res.data,
        });
        setLoading(false);
      })
      .catch((err) => {});
  };

  if (loading) {
    return <HashLoader color="#ff9100" style={styles.loading} />;
  }

  return (
    <>
      <div className="container">
        <div className="row" style={styles.row}>
          <SectionFilter
            needId={needId}
            filterNeed={filterNeed}
            filterFamily={filterFamily}
            filterFamilyId={filterFamilyId}
            setFilterFamilyId={setFilterFamilyId}
          />
          <SectionProduct />
        </div>
      </div>
      <Gap height={50} />
    </>
  );
};

export default Product;

const styles = {
  row: { marginLeft: -10, marginRight: -10 },
  loading: {
    justifyContent: "center",
  },
};
