import M from "materialize-css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HashLoader } from "react-spinners";
import { toast } from "react-toastify";
import Gap from "../../components/atoms/Gap";
import SectionAmount from "../../components/organisms/Cart/SectionAmount";
import SectionForm from "../../components/organisms/Cart/SectionForm";
import SectionItem from "../../components/organisms/Cart/SectionItem";
import { authentication } from "../../redux/action/auth";
import { getCart, getTotalItem, updateCart } from "../../redux/action/cart";
import { storeOrder } from "../../redux/action/order";

const Cart = () => {
  useEffect(() => {
    var elems = document.querySelectorAll("select");
    M.FormSelect.init(elems, {});
  });

  let [searchParams] = useSearchParams();
  const direct = searchParams.get("direct");

  const [loading, setLoading] = useState(true);
  const [note, setNote] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [validation, setValidation] = useState([]);

  const { cart } = useSelector((state) => state.cart);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("token-customer")) {
      window.location.href = "/login";
    }

    authentication()
      .then(() => {
        getCart(direct).then((res) => {
          dispatch({
            type: "GET_CART",
            payload: res.data,
          });
        });
      })
      .catch((err) => {
        if (err.message === "invalid token") {
          window.location.href = "/login";
        } else {
          console.log(err);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const btnQuantity = (itemId, value) => {
    setLoading(true);

    const form = {
      itemId,
    };

    updateCart(form, direct, value)
      .then(() => {
        getCart(direct, paymentMethod).then((res) => {
          dispatch({
            type: "GET_CART",
            payload: res.data,
          });
          dispatch(getTotalItem());
        });
      })
      .catch((err) => {
        toast(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const selectPaymentMethod = (value) => {
    setLoading(true);
    setPaymentMethod(value);
    getCart(direct, value)
      .then((res) => {
        dispatch({
          type: "GET_CART",
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const navigate = useNavigate();

  const btnCheckout = () => {
    setLoading(true);
    const form = {
      note,
      paymentMethod,
      totalItem: cart.rawTotalItem,
      totalWeight: cart.rawTotalWeight,
      totalPrice: cart.rawTotalPrice,
      serviceFee: cart.rawServiceFee,
      paymentTax: cart.rawPaymentTax,
      deliveryFee: cart.rawDeliveryFee,
      distance: cart.rawDistance,
      grossAmount: cart.rawGrossAmount,
    };

    storeOrder(form, direct)
      .then((res) => {
        toast(res.message);
        if (paymentMethod === "cod") {
          navigate("/order?status=success");
        } else {
          navigate("/order?status=pending");
        }
      })
      .catch((err) => {
        if (err.message === "validation failed") {
          setValidation(err.data);
        } else {
          toast(err.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <HashLoader color="#ff9100" style={styles.loading} />;
  }

  return (
    <>
      <div className="container">
        <SectionItem cart={cart} btnQuantity={btnQuantity} />
        <SectionForm
          note={note}
          setNote={setNote}
          selectPaymentMethod={selectPaymentMethod}
          paymentMethod={paymentMethod}
          validation={validation}
        />
        <SectionAmount cart={cart} />
      </div>
      <Gap height={10} />
      <div className="center">
        <a
          href="#"
          className="btn-large"
          onClick={() => {
            btnCheckout();
          }}
        >
          <span>Pesan Dan Antar Sekarang</span>
        </a>
      </div>
      <Gap height={85} />
    </>
  );
};

export default Cart;

const styles = {
  loading: {
    justifyContent: "center",
  },
};
