import Gap from "../../atoms/Gap";

const SectionForm = ({
  note,
  setNote,
  selectPaymentMethod,
  paymentMethod,
  validation,
}) => {
  return (
    <div className="card" style={styles.card}>
      <div className="input-field">
        <input
          type="text"
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
        <label className="active">Catatan</label>
      </div>
      <div className="input-field">
        <select
          value={paymentMethod}
          onChange={(e) => {
            selectPaymentMethod(e.target.value);
          }}
        >
          <option value="" disabled>
            Pilih Metode Pembayaran
          </option>

          <option value="cod">COD</option>
          <option value="transfers">Transfer</option>
          <option value="e-wallets">E-Wallet</option>
        </select>
        <label>Metode Pembayaran</label>
        {validation?.map((value, index) =>
          value.field === "paymentMethod" ? (
            <span className="helper-text red-text" key={index}>
              {value.message}
            </span>
          ) : null
        )}
        {(() => {
          if (paymentMethod === "cod")
            return (
              <>
                <Gap height={10} />
                <span>Siapkan Uang Pas!!!</span>
              </>
            );
          if (paymentMethod === "transfers")
            return (
              <>
                <Gap height={10} />
                <span>Ready : BRI & Jago</span>
              </>
            );
          if (paymentMethod === "e-wallets")
            return (
              <>
                <Gap height={10} />
                <span>Ready : Gopay, ShopeePay & QRIS</span>
              </>
            );
        })()}
      </div>
    </div>
  );
};

export default SectionForm;

const styles = {
  card: { padding: 10, borderRadius: 10 },
};
