import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Gap from "../../components/atoms/Gap";
import { authentication } from "../../redux/action/auth";
import { getAll } from "../../redux/action/merchantCustomerChat";
import { HashLoader } from "react-spinners";

const Chat = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("token-customer")) {
      authentication()
        .then((res) => {
          dispatch({
            type: "GET_USER",
            payload: res.data,
          });
          getAll()
            .then((res) => {
              dispatch({
                type: "GET_MERCHANTS",
                payload: res.data,
              });
              setLoading(false);
            })
            .catch((err) => {});
        })
        .catch((err) => {
          window.location.href = "/login";
        });
    } else {
      window.location.href = "/login";
    }
  }, [dispatch]);

  const { merchants } = useSelector((state) => state.merchants);

  if (loading) {
    return <HashLoader color="#ff9100" style={styles.loading} />;
  }

  return (
    <>
      <div className="container">
        {merchants.map((value, index) => (
          <a
            href={`/chat/merchant/${value.merchantId}`}
            className="grey-text text-darken-3"
            key={index}
          >
            <div className="card" style={styles.card}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                key={index}
              >
                <div style={styles.containerImage}>
                  <img
                    src={`//${value.image}`}
                    className="responsive-img"
                    style={styles.image}
                  />
                </div>
                <div style={styles.merchant}>
                  {value.Merchant?.name}{" "}
                  {value.unread ? (
                    <b className="red-text">({value.unread})</b>
                  ) : null}
                </div>
              </div>
            </div>
          </a>
        ))}
      </div>
      <Gap height={70} />
    </>
  );
};

export default Chat;

const styles = {
  card: { padding: 10, borderRadius: 10 },
  containerImage: { marginRight: 10 },
  image: { width: 50, height: 50 },
  merchant: { fontSize: 16 },
  loading: {
    justifyContent: "center",
  },
};
